import { UserProfileDoc } from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import { UserProfile, UserProfileData } from 'src/types'

import { getFirebaseEntity, updateEntity, useFirebaseEntity } from './firebase'

const COLLECTION_NAME = 'users'

function getUserProfileFromDoc(
  data: UserProfileDoc
): UserProfileData | undefined {
  if (!data) return undefined
  return data
}

function getUpdateData(
  updates: Partial<UserProfileData>
): Partial<UserProfileDoc> {
  return omit(updates, ['isFreeUser', 'isProUser'])
}

export async function updateCurrentUserProfile(
  userId: string | null,
  updates: Partial<UserProfileData>
): Promise<boolean> {
  if (!userId) {
    return false
  }

  return updateEntity<UserProfileData, UserProfileDoc>(
    COLLECTION_NAME,
    userId,
    updates,
    getUpdateData,
    userId
  )
}

type PartialUser = {
  id: string | null
  emailVerified: boolean
}

export function useUserProfile(user: PartialUser) {
  const { entity: userProfile, loading } = useFirebaseEntity<
    UserProfile,
    UserProfileData,
    UserProfileDoc
  >(
    COLLECTION_NAME,
    user.id as string,
    getUserProfileFromDoc,
    getUpdateData,
    true,
    // Skip fetching user from Firestore when not email verified as we
    // prevent all calls from unverified users
    user.id === null || !user.emailVerified
  )
  return { userProfile, loading }
}

export async function getUserProfile(userId: string | null) {
  if (!userId) return
  const flow = getFirebaseEntity<UserProfile, UserProfileData, UserProfileDoc>(
    COLLECTION_NAME,
    userId,
    getUserProfileFromDoc,
    getUpdateData
  )

  return flow
}
