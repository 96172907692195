import { useAuthUser } from 'next-firebase-auth'
import { useMemo } from 'react'
import { useTeam } from 'src/store/team'
import { Team } from 'src/types'

export function useCombinedTeam(teamFromServer: Team | null): Team | null {
  const authUser = useAuthUser()
  const { team, loading } = useTeam(teamFromServer?.id)

  return useMemo(() => {
    if (!authUser.id || !authUser.email || (!teamFromServer && !team)) {
      return null
    }

    const combinedTeam = {
      ...teamFromServer,
      ...(!loading && team ? team : {}),
    } as Team

    return combinedTeam
  }, [authUser, teamFromServer, loading, team])
}
