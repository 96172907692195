import omit from 'lodash/omit'
import { useAuthUser } from 'next-firebase-auth'
import { useCallback, useMemo } from 'react'
import { defaultUser } from 'src/auth/AuthContext'
import { User, UserProfile } from 'src/types'

export function useCombinedUser(userProfileCombined: UserProfile | null): User {
  const authUser = useAuthUser()

  const signOut = useCallback(async () => {
    await authUser.signOut()
  }, [authUser])

  return useMemo(() => {
    if (!authUser.id || !authUser.email) {
      return defaultUser
    }

    const combinedUserProfile = {
      ...defaultUser,
      ...omit(authUser, 'serialize'),
      ...(userProfileCombined ? userProfileCombined : {}),
    }

    // see User type for more info about usage
    return {
      ...combinedUserProfile,
      isLoggedIn: Boolean(authUser.id),
      email: combinedUserProfile?.email ?? authUser.email,
      emailDomain: (combinedUserProfile?.email ?? authUser.email).split('@')[1],
      name: combinedUserProfile?.name ?? authUser.displayName,
      photoURL: combinedUserProfile?.photoURL ?? authUser.photoURL,
      signOut,
      group: combinedUserProfile?.group ?? null,
      intercomUserId: combinedUserProfile?.intercomUserId ?? null,
      intercomUserHash: combinedUserProfile?.intercomUserHash ?? null,
      prefs: combinedUserProfile?.prefs ?? null,
      features: combinedUserProfile?.features ?? null,
      currentSubscriber:
        combinedUserProfile?.currentSubscriber ?? defaultUser.currentSubscriber,
      customerId: combinedUserProfile?.customerId ?? null,
      subscriptionDate: combinedUserProfile?.subscriptionDate ?? null,
      activeTeamId: combinedUserProfile?.activeTeamId ?? null,
      isActiveMemberOfTeam:
        combinedUserProfile?.isActiveMemberOfTeam ??
        defaultUser.isActiveMemberOfTeam,
      isExternalMemberOfTeam:
        combinedUserProfile?.isExternalMemberOfTeam ??
        defaultUser.isExternalMemberOfTeam,
      isTeamAdmin: combinedUserProfile?.isTeamAdmin ?? defaultUser.isTeamAdmin,
      hasActiveInvitationToTeam:
        combinedUserProfile?.hasActiveInvitationToTeam ??
        defaultUser.hasActiveInvitationToTeam,
      onboardingUseCases: {
        Marketing:
          combinedUserProfile?.onboardingUseCases?.Marketing ??
          defaultUser.onboardingUseCases?.Marketing,
        Product:
          combinedUserProfile?.onboardingUseCases?.Product ??
          defaultUser.onboardingUseCases?.Product,
        Sales:
          combinedUserProfile?.onboardingUseCases?.Sales ??
          defaultUser.onboardingUseCases?.Sales,
        CustomerSuccess:
          combinedUserProfile?.onboardingUseCases?.CustomerSuccess ??
          defaultUser.onboardingUseCases?.CustomerSuccess,
        Other:
          combinedUserProfile?.onboardingUseCases?.Other ??
          defaultUser.onboardingUseCases?.Other,
      },
      onboardingCompanySize:
        defaultUser?.onboardingCompanySize ?? defaultUser.onboardingCompanySize,
      getStartedArticlesHidden:
        defaultUser?.getStartedArticlesHidden ??
        defaultUser.getStartedArticlesHidden,
    } as User
  }, [authUser, userProfileCombined, signOut])
}
